import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    t: (key, ...params) => window.i18n.t(key, params),
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#141414',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      dark: {
        primary: '#fff',
        secondary: '#141414'
      }
    },
  },
});
