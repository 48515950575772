const Integrations = () => import(/* webpackChunkName: "integrations-index" */ '@/pages/integrations/index')
const ApiIntegration = () => import(/* webpackChunkName: "api-integration" */ '@/pages/integrations/api')
const ApiTokens = () => import(/* webpackChunkName: "integration-api-tokens" */ '@/pages/integrations/api/tokens')

const ContainerRegistries = () => import(/* webpackChunkName: "integration-containerRegistries-index" */ '@/pages/integrations/containerRegistries/index')
const ListContainerRegistries = () => import(/* webpackChunkName: "integration-containerRegistries-list" */ '@/pages/integrations/containerRegistries/list')
const CreateContainerRegistry = () => import(/* webpackChunkName: "integration-containerRegistries-create" */ '@/pages/integrations/containerRegistries/create')
const EditContainerRegistry = () => import(/* webpackChunkName: "integration-containerRegistries-edit" */ '@/pages/integrations/containerRegistries/edit')

const GitIntegration = () => import(/* webpackChunkName: "git-integration" */ '@/pages/integrations/git')
const GitAccounts = () => import(/* webpackChunkName: "integration-git-accounts" */ '@/pages/integrations/git/accounts')

export default [
    {
        path: '/integrations',
        component: Integrations,
        meta: {
            breadcrumb: 'breadcrumb.integrations.title',
            breadcrumb_to: {name: "integrations.api.tokens"}
        },
        children: [
            {path: '', redirect: {name: 'integrations.api.tokens'}},
            {
                path: 'api',
                name: 'integrations.api',
                component: ApiIntegration,
                meta: {
                    breadcrumb: 'breadcrumb.integrations.api.title'
                },
                children: [
                    {
                        path: 'tokens',
                        name: 'integrations.api.tokens',
                        component: ApiTokens,
                        meta: {
                            breadcrumb: 'breadcrumb.integrations.api.tokens'
                        }
                    }
                ]
            },
            {
                path: 'container-registries',
                name: 'integrations.containerRegistries',
                component: ContainerRegistries,
                meta: {
                    breadcrumb: 'breadcrumb.integrations.containerRegistries.title',
                    breadcrumb_to: {name: "integrations.containerRegistries.list"}
                },
                children: [
                    {
                        path: 'list',
                        name: 'integrations.containerRegistries.list',
                        component: ListContainerRegistries,
                        meta: {
                            breadcrumb: 'breadcrumb.integrations.containerRegistries.list'
                        }
                    },
                    {
                        path: 'create',
                        name: 'integrations.containerRegistries.create',
                        component: CreateContainerRegistry,
                        meta: {
                            breadcrumb: 'breadcrumb.integrations.containerRegistries.create'
                        }
                    },
                    {
                        path: ':id/edit',
                        name: 'integrations.containerRegistries.edit',
                        component: EditContainerRegistry,
                        meta: {
                            breadcrumb: 'breadcrumb.integrations.containerRegistries.edit'
                        }
                    },
                ]
            },
            {
                path: 'git',
                name: 'integrations.git',
                component: GitIntegration,
                meta: {
                    breadcrumb: 'breadcrumb.integrations.git.title'
                },
                children: [
                    {
                        path: 'accounts',
                        name: 'integrations.git.accounts',
                        component: GitAccounts,
                        meta: {
                            breadcrumb: 'breadcrumb.integrations.git.accounts'
                        }
                    }
                ]
            }
        ]
    }
]
