const Domains = () => import(/* webpackChunkName: "domains-index" */ '@/pages/domains/index')
const ListDomains = () => import(/* webpackChunkName: "list-domains" */ '@/pages/domains/list')
const CreateDomain = () => import(/* webpackChunkName: "select-domain-type" */ '@/pages/domains/create.vue')
const CreateRegistration = () => import(/* webpackChunkName: "add-domain-order" */ '@/pages/domains/create-registration')
const CreateExternalDomain = () => import(/* webpackChunkName: "add-external-domain" */ '@/pages/domains/create-external')
const EditDomain = () => import(/* webpackChunkName: "edit-domain" */ '@/pages/domains/edit/index.vue')
const EditDomainOptionsGeneral = () => import(/* webpackChunkName: "edit-domain-options-general" */ '@/pages/domains/edit/options-general')
// const ConfirmExternalDomain = () => import(/* webpackChunkName: "confirm-external-domain" */ '@/pages/domains/add/confirm-external')

const DomainPricing = () => import(/* webpackChunkName: "domain-pricing" */ '@/pages/domain-pricing/index')
const ListDomainPricing = () => import(/* webpackChunkName: "domain-pricing-list" */ '@/pages/domain-pricing/list')

const DomainHandles = () => import(/* webpackChunkName: "domain-handles" */ '@/pages/domain-handles')
const ListDomainHandles = () => import(/* webpackChunkName: "list-domain-handles" */ '@/pages/domain-handles/list')
const CreateDomainHandle = () => import(/* webpackChunkName: "create-domain-handle" */ '@/pages/domain-handles/create')
const EditDomainHandle = () => import(/* webpackChunkName: "edit-domain-handle" */ '@/pages/domain-handles/edit')

const NameserverZones = () => import(/* webpackChunkName: "nameserver-zones" */ '@/pages/dns-zones')
const ListNameserverZones = () => import(/* webpackChunkName: "list-nameserver-zones" */ '@/pages/dns-zones/list')
const AddNameserverZone = () => import(/* webpackChunkName: "add-nameserver-zone" */ '@/pages/dns-zones/add')

const NameserverZoneRecords = () => import(/* webpackChunkName: "nameserver-zone-records" */ '@/pages/dns-zones/records')
const ListNameserverZoneRecords = () => import(/* webpackChunkName: "list-nameserver-zone-records" */ '@/pages/dns-zones/records/list')
const AddNameserverZoneRecord = () => import(/* webpackChunkName: "add-nameserver-zone-record" */ '@/pages/dns-zones/records/add')
const EditNameserverZoneRecord = () => import(/* webpackChunkName: "edit-nameserver-zone-record" */ '@/pages/dns-zones/records/edit')

export default [
    {
        path: '/domains',
        component: Domains,
        meta: {
            breadcrumb: 'breadcrumb.domains.title',
            breadcrumb_to: {name: 'domains.list' }
        },
        children: [
            {path: '', redirect: {name: 'domains.list'}},
            {
                path: 'list',
                name: 'domains.list',
                component: ListDomains,
                meta: {
                    breadcrumb: 'breadcrumb.domains.list'
                }
            },
            {
                path: ':domain_id/edit',
                name: 'domains.edit',
                component: EditDomain,
                meta: {
                    sideNav: 'edit-domain',
                    breadcrumb: 'Edit Domain',
                    breadcrumb_to: {name: 'spaces.edit.options.general'}
                },
                redirect: {name: "domains.edit.options.general"},
                children: [
                    {
                        path: 'general',
                        name: 'domains.edit.options.general',
                        component: EditDomainOptionsGeneral,
                        meta: {
                            sideNav: 'edit-domain',
                            breadcrumb: 'General'
                        }
                    },
                ]
            },
            // {
            //     path: ':domain_id/confirm',
            //     name: 'domains.confirm',
            //     component: ConfirmExternalDomain,
            //     meta: {
            //         breadcrumb: 'Confirm domain ownership'
            //     }
            // },
            {
                path: 'create',
                name: 'domains.create',
                component: CreateDomain,
                meta: {
                    breadcrumb: 'Add domain'
                }
            },
            {
                path: 'create/registration',
                name: 'domains.create.registration',
                component: CreateRegistration,
                meta: {
                    breadcrumb: 'Domain registration'
                }
            },
            {
                path: 'create/external',
                name: 'domains.create.external',
                component: CreateExternalDomain,
                meta: {
                    breadcrumb: 'Add external domain'
                }
            },
            {
                path: 'pricing',
                component: DomainPricing,
                meta: {
                    breadcrumb: 'breadcrumb.domains.pricing.title',
                    breadcrumb_to: {name: 'domains.pricing.list' }
                },
                children: [
                    {
                        path: '',
                        redirect: {name: 'domains.pricing.list'}
                    },
                    {
                        path: 'list',
                        name: 'domains.pricing.list',
                        component: ListDomainPricing,
                        meta: {
                            breadcrumb: 'breadcrumb.domains.pricing.list'
                        }
                    },
                ]
            },
            {
                path: 'handles',
                component: DomainHandles,
                meta: {
                    breadcrumb: 'Domain contacts',
                    breadcrumb_to: {name: 'domains.handles.list'}
                },
                children: [
                    {path: '', redirect: {name: 'domains.handles.list'}},
                    {
                        path: 'list',
                        name: 'domains.handles.list',
                        component: ListDomainHandles,
                        meta: {
                            breadcrumb: 'Overview'
                        }
                    },
                    {
                        path: 'create',
                        name: 'domains.handles.create',
                        component: CreateDomainHandle,
                        meta: {
                            breadcrumb: 'Create domain contact'
                        }
                    },
                    {
                        path: ':id/edit',
                        name: 'domains.handles.edit',
                        component: EditDomainHandle,
                        meta: {
                            breadcrumb: 'Edit domain contact'
                        }
                    }
                ]
            },
            {
                path: 'zones',
                component: NameserverZones,
                meta: {
                    breadcrumb: 'breadcrumb.domains.zones.title',
                    breadcrumb_to: {name: 'domains.zones.list'}
                },
                children: [
                    {path: '', redirect: {name: 'domains.zones.list'}},
                    {
                        path: 'list',
                        name: 'domains.zones.list',
                        component: ListNameserverZones,
                        meta: {
                            breadcrumb: 'breadcrumb.domains.zones.list'
                        }
                    },
                    {
                        path: 'add',
                        name: 'domains.zones.add',
                        component: AddNameserverZone,
                        meta: {
                            breadcrumb: 'breadcrumb.domains.zones.add'
                        }
                    },
                    {
                        path: ':nameserver_zone_id/records',
                        component: NameserverZoneRecords,
                        meta: {
                            breadcrumb: 'breadcrumb.domains.zones.records.title',
                            breadcrumb_to: {name: 'domains.zones.records.list'}
                        },
                        children: [
                            {path: '', redirect: {name: 'domains.zones.records.list'}},
                            {
                                path: 'list',
                                name: 'domains.zones.records.list',
                                component: ListNameserverZoneRecords,
                                meta: {
                                    breadcrumb: 'breadcrumb.domains.zones.records.list'
                                }
                            },
                            {
                                path: 'add',
                                name: 'domains.zones.records.add',
                                component: AddNameserverZoneRecord,
                                meta: {
                                    breadcrumb: 'breadcrumb.domains.zones.records.add'
                                }
                            },
                            {
                                path: ':record_id/edit',
                                name: 'domains.zones.records.edit',
                                component: EditNameserverZoneRecord,
                                meta: {
                                    breadcrumb: 'breadcrumb.domains.zones.records.edit'
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
