import './scss/app.scss'
import Vue from 'vue'
import App from './App.vue'
import getEnv from '@/plugins/env'

import i18n from './i18n'
window.i18n = i18n

import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

import Toasted from 'vue-toasted';
Vue.use(Toasted, {router});

import axios from './plugins/axios';
window.axios = axios

import moment from 'moment/moment';
Vue.prototype.moment = moment

import lodash from 'lodash'
Vue.prototype._ = lodash
window._ = lodash

import VueBreadcrumbs from 'vue-breadcrumbs'
Vue.use(VueBreadcrumbs)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import 'vue-select/src/css/vue-select.css'
import vueSelect from 'vue-select'
Vue.component('vue-select', vueSelect)

import Echo from "laravel-echo"
window.Pusher = require('pusher-js')
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: getEnv('VUE_APP_PUSHER_APP_KEY'),
  wsHost: getEnv('VUE_APP_WEBSOCKET_HOST'),
  wsPort: getEnv('VUE_APP_WEBSOCKET_PORT'),
  disableStats: true,
  forceTLS: false,
  enabledTransports: ['ws', 'wss']
});

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== "production"
Vue.config.performance = process.env.NODE_ENV !== "production"

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
